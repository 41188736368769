
#login_composer  button 
{
    border-radius: 7px;
    margin: 4% 0 0 0;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(to right,#FB3570 50%, #F5564C 100%);
    
}


@media screen and (max-width: 720px)
{
    #login_composer
    {
        width: 95%;
        margin: 40% 0 0 0;
    }
}

@media screen and (min-width: 720px)
{
    #login_composer
    {
        width: 45%;
        margin: 10% 0 0 0;
    }
}